<template>
  <div class="releaseServiceSuccess">
    <div class="releaseServiceSuccess-tip">
      <div class="releaseServiceSuccess-tip-top">
        <img src="~a/workbench/releaseServiceSuccess.png" alt="" />
        <p>成功发布服务!</p>
      </div>
      <div class="releaseServiceSuccess-tip-bottom">
        <p>继续发布或查看我的服务</p>
        <div class="link-group">
          <router-link class="link" to="/workbench/releaseService">
            <img src="~a/workbench/continueReleaseService.png" alt="" />
            <span>继续发布</span>
          </router-link>
          <router-link class="link" to="/workbench/myService">
            <img src="~a/workbench/checkMyService.png" alt="" />
            <span>查看我的服务</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReleaseServiceSuccess'
};
</script>

<style lang="less" scoped>
.releaseServiceSuccess {
  &-tip {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 347px;
    text-align: center;
    &-top {
      margin-bottom: 24px;
      img {
        width: 347px;
        height: 233px;
        margin-bottom: 47px;
      }
      p {
        color: #507cff;
        font-size: 26px;
      }
    }
    &-bottom {
      p {
        margin-bottom: 24px;
        color: #666666;
        font-size: 20px;
      }
      .link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 117px;
        height: 127px;
        background: #f7f9fc;
        border: 1px solid transparent;
        border-radius: 6px;
        color: #666666;
        font-size: 16px;
        &:hover {
          border-color: rgba(80, 124, 255, 0.3);
          background: rgba(80, 124, 255, 0.1);
          box-shadow: 0px 5px 10px 1px rgba(236, 239, 255, 0.5);
        }
        img {
          width: 54px;
          height: 54px;
          margin-bottom: 14px;
        }
        &-group {
          display: flex;
          justify-content: space-evenly;
        }
      }
    }
  }
}
</style>
